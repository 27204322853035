@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	/* Light */
	@font-face {
		font-family: 'Satoshi';
		src: url('/public/fonts/Satoshi-Light.woff2') format('woff2'),
			url('/public/fonts/Satoshi-Light.woff') format('woff');
		font-weight: 300;
		font-style: normal;
		font-display: swap;
	}

	/* Regular */
	@font-face {
		font-family: 'Satoshi';
		src: url('/public/fonts/Satoshi-Regular.woff2') format('woff2'),
			url('/public/fonts/Satoshi-Regular.woff') format('woff');
		font-weight: 400;
		font-style: normal;
		font-display: swap;
	}

	/* Medium */
	@font-face {
		font-family: 'Satoshi';
		src: url('/public/fonts/Satoshi-Medium.woff2') format('woff2'),
			url('/public/fonts/Satoshi-Medium.woff') format('woff');
		font-weight: 500;
		font-style: normal;
		font-display: swap;
	}

	/* Bold */
	@font-face {
		font-family: 'Satoshi';
		src: url('/public/fonts/Satoshi-Bold.woff2') format('woff2'),
			url('/public/fonts/Satoshi-Bold.woff2') format('woff');
		font-weight: 700;
		font-style: normal;
		font-display: swap;
	}

	/* Black */
	@font-face {
		font-family: 'Satoshi';
		src: url('/public/fonts/Satoshi-Black.woff2') format('woff2'),
			url('/public/fonts/Satoshi-Black.woff') format('woff');
		font-weight: 900;
		font-style: normal;
		font-display: swap;
	}

	html {
		font-family: 'Satoshi', sans-serif;
	}
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

/* Wrapper for the phone number input */
.sc-hBxdNW {
	display: flex;
	align-items: center;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 8px 12px;
	width: 100%;
	max-width: 320px;
}

/* Flag and country code section */
.sc-gGvHzb {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding-right: 10px;
	border-right: 1px solid #ccc;
}

.sc-gGvHzb img {
	width: 20px;
	height: 15px;
	margin-right: 5px;
}

.sc-gGvHzb p {
	margin: 0;
	font-size: 14px;
	color: #333;
}

/* Input field */
.sc-ksBlDV input {
	border: none;
	outline: none;
	font-size: 14px;
	color: #333;
	flex-grow: 1;
	padding-left: 10px;
}

.sc-ksBlDV input::placeholder {
	color: #999;
}

/* SVG Arrow Icon */
.sc-gGvHzb svg {
	margin-left: 5px;
	fill: #999;
	transition: transform 0.2s ease;
}

/* Dropdown styling */
.sc-ckDZVO {
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #fff;
	border: 1px solid #ddd;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	max-height: 200px;
	overflow-y: auto;
	border-radius: 5px;
	width: 100%;
	max-width: 320px;
	z-index: 1000;
}

/* Search Bar in Dropdown */
.sc-ksBlDV .sc-hBxdNW input {
	width: 100%;
	padding: 8px;
	border: none;
	border-bottom: 1px solid #ddd;
	outline: none;
	font-size: 14px;
}

/* Country list items */
.sc-ckDZVO ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.sc-ckDZVO ul li {
	display: flex;
	align-items: center;
	padding: 10px;
	cursor: pointer;
	transition: background-color 0.2s ease;
}

.sc-ckDZVO ul li:hover {
	background-color: #f1f1f1;
}

.sc-ckDZVO ul li .image {
	margin-right: 10px;
}

.sc-ckDZVO ul li img {
	width: 20px;
	height: 15px;
}

.sc-ckDZVO ul li p {
	margin: 0;
	font-size: 14px;
	color: #333;
}


